import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ActivityBarChart from "../ActivityBarChart";
import { UserContext } from "../../../context/UserContext";
import UserProfile from "../../User/UserProfile";
import ThriveModal from "../../common/ThriveModal";
import CloseIcon from "@mui/icons-material/Close";
import IDEACoreCRM from "../../IDEACoreCRM/IDEACoreCRM";
import DateFilterForm from "./DateFilterForm";
import dayjs, { Dayjs } from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import utc from "dayjs/plugin/utc";
import CaseCard from "../../IDEACoreCRM/CaseCard";
import FirmReportPdf from "../../FirmReport/FirmReportPdf";
import BFCReportPdf from "../../BFCReport/BFCReportPdf";
import AllBFCReport from "../../BFCReport/AllBFCReport";
dayjs.extend(utc);
dayjs.extend(dayOfYear);

const userOrder = [
  "andrewsa@oaklandthrive.org",
  "peguesed@oaklandthrive.org",
  "brayj@oaklandthrive.org",
  "garcias@oaklandthrive.org",
  "desanctisj@oaklandthrive.org",
  "ibej@oaklandthrive.org",
  "dockhamj@oaklandthrive.org",
  "kregerj@oaklandthrive.org",
  "lyonsm@oaklandthrive.org",
  "williamst@oaklandthrive.org",
  "beckerb@oaklandthrive.org",
  "tellezd@oaklandthrive.org",
  "bauerd@oaklandthrive.org",
  "alleng@oaklandthrive.org",
  "phiferd@oaklandthrive.org",
  "brikhow@oaklandthrive.org",
];

const UserStatisticsLine = (props: any) => {
  const [userCaseActivity, setUserCaseActivity] = useState({});
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  useEffect(() => {
    if (props.startDate === null && props.endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${props.user.email}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    } else if (props.startDate !== null && props.endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${
            props.user.email
          }&startDate=${props.startDate.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    } else if (props.startDate !== null && props.endDate !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${
            props.user.email
          }&startDate=${props.startDate.toDate()}&endDate=${props.endDate?.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    }
  }, [props.startDate, props.endDate]);
  return props.user.role === "bfc" ? (
    <div>
      <Stack direction="row">
        <Box
          className="rounded-l-2xl"
          sx={{ backgroundColor: "#9D2362", width: "20%", margin: 0 }}
        >
          <UserProfile user={props.user}></UserProfile>
        </Box>

        <Box
          className="rounded-r-2xl shadow-md inset-0 h-[300px] bg-white"
          style={{ width: "80%" }}
        >
          <InteractiveBarChart
            data={userCaseActivity}
            user={props.user}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        </Box>
      </Stack>
    </div>
  ) : (
    <></>
  );
};

const InteractiveBarChart = (props: any) => {
  const [cardOpen, setCardOpen] = useState(false);
  const [barKey, setBarKey] = useState("");
  const [activityCaseMapping, setActivityCaseMapping] = useState<{
    [key: string]: any[];
  }>({});
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  useEffect(() => {
    if (props.startDate === null && props.endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/activityCaseMapping?email=${props.user.email}`,
          axiosConfig
        )
        .then((res) => {
          setActivityCaseMapping(res.data.result);
        });
    } else if (props.startDate !== null && props.endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/activityCaseMapping?email=${
            props.user.email
          }&startDate=${props.startDate.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setActivityCaseMapping(res.data.result);
        });
    } else if (props.startDate !== null && props.endDate !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/activityCaseMapping?email=${
            props.user.email
          }&startDate=${props.startDate.toDate()}&endDate=${props.endDate?.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setActivityCaseMapping(res.data.result);
        });
    }
  }, [props.startDate, props.endDate]);

  return (
    <div className="h-full">
      <ActivityBarChart
        data={props.data}
        barClickable={true}
        setCardOpen={setCardOpen}
        setBarKey={setBarKey}
      />
      <ThriveModal
        width={1280}
        open={cardOpen}
        setOpen={setCardOpen}
        contentComponent={
          <Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                {props.user.role === "admin"
                  ? barKey
                  : `${props.user.firstName} ${props.user.lastName} - ${barKey}`}
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setCardOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <div className="grid grid-cols-8 gap-2 overflow-auto max-h-[550px]">
              {activityCaseMapping[barKey] &&
                activityCaseMapping[barKey].map((caseItem, index) => (
                  <CaseCard case={caseItem} key={index} firm={{}} />
                ))}
            </div>
          </Box>
        }
      ></ThriveModal>
    </div>
  );
};

const ProgramStatistics = () => {
  const [selectedUser, setSelectedUser] = useState({
    firstName: "",
    lastName: "",
  });
  const [selectedUserCases, setSelectedUserCases] = useState([]);
  const [selectedUserActivities, setSelectedUserActivities] = useState([]);
  const [selectedUserFirms, setSelectedUserFirms] = useState([]);
  const [userCaseActivity, setUserCaseActivity] = useState({});
  const [users, setUsers] = useState<any[]>([]);
  const { user } = useContext(UserContext);
  const [cardOpen, setCardOpen] = useState<boolean>(false);
  const [bfcReportOpen, setBfcReportOpen] = useState<boolean>(false);
  const [allBfcReportOpen, setAllBfcReportOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().dayOfYear(1)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/user/allUsers`, axiosConfig)
      .then((res) => {
        const reorderedUsers = reorderUsers(res.data.users);
        setUsers(reorderedUsers);
      });
  }, []);

  useEffect(() => {
    //handling null cases sperately is necessary because if we send null to the backend, it gets converted to string and it is hard to managae cases.
    //skipping case where start date is null and end date is not null because it does not make sense here.
    if (startDate === null && endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${user.email}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    } else if (startDate !== null && endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${
            user.email
          }&startDate=${startDate.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    } else if (startDate !== null && endDate !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${
            user.email
          }&startDate=${startDate.toDate()}&endDate=${endDate?.toDate()}`,
          axiosConfig
        )
        .then((res) => {
          setUserCaseActivity(res.data.userStats.userCaseActivity);
        });
    }
  }, [startDate, endDate]);

  const reorderUsers = (users: any) => {
    return userOrder
      .map((email) => users.find((user: any) => user.email === email))
      .filter((user) => user !== undefined);
  };

  const getSelectedUserCasesAndActivities = async (user: any) => {
    const caseResponse = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/cases?user_id=${user._id}`,
      axiosConfig
    );
    const activityResponse = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/activity?user_id=${user._id}`,
      axiosConfig
    );

    let cases = caseResponse.data.case;
    let activities = activityResponse.data.activities;
    let firmIds = cases.map((openCase: any) => {
      return openCase.firm;
    });
    const firmResponse = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/firms/massive_get`,
      { ids: firmIds.join(",") },
      axiosConfig
    );
    setSelectedUserFirms(firmResponse.data.firms);

    if (startDate !== null && endDate === null) {
      activities = activities.filter((activity: any) => {
        return (
          dayjs(activity.created_at).utc().format("YYYY-MM-DD") >=
          startDate.format("YYYY-MM-DD")
        );
      });
    } else if (startDate !== null && endDate !== null) {
      activities = activities.filter((activity: any) => {
        return (
          dayjs(activity.created_at).utc().format("YYYY-MM-DD") >=
            startDate.format("YYYY-MM-DD") &&
          dayjs(activity.created_at).utc().format("YYYY-MM-DD") <=
            endDate.format("YYYY-MM-DD")
        );
      });
    }
    setSelectedUserCases(cases);
    setSelectedUserActivities(activities);
  };

  const handleDateFilterReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Box>
      <Stack spacing={2}>
        <div className="rounded-2xl shadow-md inset-0 h-[300px]">
          {/* <ActivityBarChart data={userCaseActivity} /> */}
          <InteractiveBarChart
            data={userCaseActivity}
            user={user}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <DateFilterForm
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onReset={handleDateFilterReset}
            />
          </div>
          <div>
            <div className="ml-3 bg-magenta hover:bg-yellow text-white font-bold py-4 px-7 rounded shadow hover:cursor-pointer">
              <button onClick={() => setAllBfcReportOpen(true)}>
                ALL BFC Report
              </button>
            </div>
          </div>
        </div>
        {users
          .filter((user: any) => {
            return user.role === "bfc";
          })
          .map((user: any, index: number) => {
            return (
              <Box key={index}>
                <UserStatisticsLine
                  user={user}
                  startDate={startDate}
                  endDate={endDate}
                ></UserStatisticsLine>
                <Box
                  sx={{
                    width: "80%",
                    float: "right",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    onClick={() => {
                      setSelectedUser(user);
                      getSelectedUserCasesAndActivities(user);

                      setCardOpen(true);
                    }}
                    sx={{ color: "#9E1F63FF", cursor: "pointer" }}
                  >
                    Case Detail
                  </Typography>
                  <Typography
                    onClick={() => {
                      setSelectedUser(user);
                      getSelectedUserCasesAndActivities(user);
                      setBfcReportOpen(true);
                    }}
                    sx={{ color: "#9E1F63FF", cursor: "pointer" }}
                  >
                    BFC Report
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Stack>

      <ThriveModal
        width={1280}
        open={cardOpen}
        setOpen={setCardOpen}
        contentComponent={
          <Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                {selectedUser.firstName} {selectedUser.lastName} Cases
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setCardOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <IDEACoreCRM
              openCases={selectedUserCases}
              caseActivities={selectedUserActivities}
              firms={selectedUserFirms}
            ></IDEACoreCRM>
          </Box>
        }
      ></ThriveModal>
      <ThriveModal
        width={1280}
        open={bfcReportOpen}
        setOpen={setBfcReportOpen}
        contentComponent={
          <Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                {selectedUser.firstName} {selectedUser.lastName} Report
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setBfcReportOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <BFCReportPdf
              user={selectedUser}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
        }
      ></ThriveModal>
      <ThriveModal
        width={1280}
        open={allBfcReportOpen}
        setOpen={setAllBfcReportOpen}
        contentComponent={
          <Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                BFC's Report
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setAllBfcReportOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <AllBFCReport startDate={startDate} endDate={endDate} />
          </Box>
        }
      ></ThriveModal>
    </Box>
  );
};
export default ProgramStatistics;
