import React from "react";
import { Box, Typography } from "@mui/material";
import UserActivity from "../User/UserActivity";
import Cookies from "js-cookie";
import axios from "axios";
import IDEACoreCRM from "../IDEACoreCRM/IDEACoreCRM";
import ThriveModal from "../common/ThriveModal";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../context/UserContext";
import Tasks from "./EventList/Tasks";
import ActivityBarChart from "./ActivityBarChart";
import ThriveCaseSelection from "../common/ThriveCaseSelection";
import dayjs, { Dayjs } from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
dayjs.extend(dayOfYear);
const Engagements = () => {
  const [cardOpen, setCardOpen] = React.useState<boolean>(false);
  const [openCases, setOpenCases] = React.useState([]);
  const [firms, setFirms] = React.useState<any[]>([]);
  const [caseActivities, setCaseActivities] = React.useState<any[]>([]);
  const [userCaseActivity, setUserCaseActivity] = React.useState({});
  const [allCaseCardOpen, setAllCaseCardOpen] = React.useState<boolean>(false);
  const [checkedCases, setCheckedCases] = React.useState<any[]>([]);
  const [selectedCase, setSelectedCase] = React.useState({ _id: "", firm: "" });
  const [displayCases, setDisplayCases] = React.useState<any>([]);
  const { user } = React.useContext(UserContext);
  React.useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        const cases = res.data.cases.filter((caseItem: any) => {
          return true;
        });
        setOpenCases(cases);

        if (cases.length > 0) {
          let case_id: string = "";
          for (let item of cases) {
            case_id = case_id + item._id + ",";
          }
          const firmdIds = cases.map((openCase: any) => {
            return openCase.firm;
          });

          axios
            .post(
              `${process.env.REACT_APP_API_DOMAIN}/activity/massive_get`,
              { case_id: case_id },
              axiosConfig
            )
            .then((res) => {
              setCaseActivities(res.data.activities);
            });

          axios
            .post(
              `${process.env.REACT_APP_API_DOMAIN}/firms/massive_get`,
              { ids: firmdIds.join(",") },
              axiosConfig
            )
            .then((res) => {
              setFirms(res.data.firms);
            });
        }
      });

    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/user/userStats?email=${
          user.email
        }&startDate=${dayjs().dayOfYear(1).toDate()}`,
        axiosConfig
      )
      .then((res) => {
        setUserCaseActivity(res.data.userStats.userCaseActivity);
      });
  }, []);

  return (
    <div className="grid grid-cols-6 gap-4">
      <div className="col-span-4">
        <div className="grid grid-rows-[100px_1fr] gap-4">
          <div className="grid grid-cols-2 gap-2">
            <div className="col-start-1 col-span-1">
              <div className="rounded-2xl shadow-md inset-0">
                <Tasks
                  caseActivities={caseActivities}
                  openCases={openCases}
                  key={1}
                  firms={firms}
                ></Tasks>
              </div>
            </div>
            <div className="col-start-2 col-span-2">
              <div className="rounded-2xl shadow-md inset-0">
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#9E1F63FF",
                    padding: "3px",
                    margin: 0,
                    color: "white",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <Typography sx={{ display: "inline-block" }} variant="h6">
                    {user.role === "admin" ? "Program Stats" : "Your Stats"}
                  </Typography>
                </Box>
                <div className="h-[300px]">
                  <ActivityBarChart data={userCaseActivity} />
                </div>
              </div>
              <div
                className="rounded-2xl shadow-md inset-0 h-[290px]"
                style={{ marginTop: "20px" }}
              >
                <Box
                  onClick={() => {
                    setCardOpen(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#9E1F63FF",
                      padding: "3px",
                      margin: 0,
                      color: "white",
                      borderRadius: "15px 15px 0 0",
                    }}
                  >
                    <Typography sx={{ display: "inline-block" }} variant="h6">
                      Case Grid
                    </Typography>
                  </Box>
                  <img
                    src={`${process.env.REACT_APP_API_DOMAIN}/images/crm.png`}
                    alt="crm"
                  ></img>
                </Box>
              </div>
            </div>
          </div>

          {/* <div className="rounded-lg shadow-md inset-0">
              <OpenCases />
            </div> */}
        </div>
      </div>
      <div className="col-span-2 ">
        <div className="rounded-lg shadow-md inset-0">
          <UserActivity
            openCases={openCases}
            open={cardOpen}
            setOpen={setCardOpen}
            setAllCaseCardOpen={setAllCaseCardOpen}
            caseActivities={caseActivities}
          />
        </div>
      </div>
      <ThriveModal
        width={1280}
        open={cardOpen}
        setOpen={setCardOpen}
        contentComponent={
          <Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                Current Cases
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setCardOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <IDEACoreCRM
              openCases={openCases}
              caseActivities={caseActivities}
              firms={firms}
            ></IDEACoreCRM>
          </Box>
        }
      ></ThriveModal>

      <ThriveModal
        open={allCaseCardOpen}
        contentComponent={
          <>
            <ThriveCaseSelection
              cases={openCases}
              displayCases={displayCases}
              setDisplayCases={setDisplayCases}
              caseActivities={caseActivities}
              checkedCases={checkedCases}
              setCheckedCases={setCheckedCases}
              selectedCase={selectedCase}
              setSelectedCase={setSelectedCase}
              enableControl={true}
              enableSelection={false}
              enableMultiSelection={false}
              setOpen={setAllCaseCardOpen}
              title={"All Cases"}
              status_default="either"
              sortby_default="recent"
              handleNext={() => {}}
            ></ThriveCaseSelection>
          </>
        }
      />
    </div>
  );
};

export default Engagements;
